import React from "react"
import {
  LogoWrapper,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  // SocialLinksWrapper,
  // QuickSearchWrapper,
} from "./style"
import NavItem from "./navItem"
// import { Icon } from "utils/getIcon"
import { ReactComponent as Logo } from "../../images/cmc-logo--full.svg"

const NavBar = () => {
  return (
    <NavBarContainer>
      <LogoWrapper to="/">
        <Logo />
      </LogoWrapper>
      <NavWrapper>
        <Nav>
          <NavList>
            <NavItem label="About" path="/about" />
            <NavItem label="Work" path="/work" />
            <NavItem label="Contact" path="/contact" />
          </NavList>
        </Nav>
      </NavWrapper>
    </NavBarContainer>
  )
}

export default NavBar
