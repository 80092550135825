import React from "react"
import { NavListItem } from "./style"
import { Link } from "gatsby"

const NavBar = ({ label, path, anchor }) => {
  return (
    <NavListItem>

      {anchor && (
        <a href={anchor}><span>{label}</span></a>
      )}

      {path && (
        <Link to={path}>
          <span>{label}</span>
        </Link>

      )}

    </NavListItem>
  )
}

export default NavBar
