import styled from "styled-components"
import { List } from "../List/index"

import { device } from "../../utils/breakpoints"

export const SocialLinksContainer = styled.div`
  a {
    margin-bottom: 0;
    :hover {
      opacity: 0.6;
      transition: 70ms;
    }

    svg {
      display: block;
      width: 24px;
      height: 24px;
      g,
      path {
        fill: #ff7f41;
      }

      :hover {
        opacity: 0.6;
        transition: 70ms;
      }
    }
  }
`
export const SocialNewList = styled(List)`
  margin-right: 25px !important;

  @media ${device.tablet} {
    padding-top: 5px;
  }
  @media ${device.desktop} {
    margin-right: 35px !important;
    padding-top: 0;

    :last-child {
      margin-right: 0;
    }
  }
`
