import React from "react"

import { FlexGrid, FlexRow } from "../FlexGrid/index"

import { ReactComponent as CommuLogoSml } from "./communicado_logo_sml.svg"

import {
  Container,
  MenuItems,
  ContactMessage,
  Message,
  Address,
  Name,
  Details,
  NewSocialLinks,
  NewFlexCol,
  ContactFlexCol,
  AddressFlexCol,
  Phone,
  Email,
} from "./footerMenuStyles"

const Divider = (props) => (
  <Container>
    <FlexGrid className={props.className} rowGap={[60, 50, 100]}>
      <FlexRow>
        <ContactFlexCol xs={12}>
          <ContactMessage>
            <Message>
              If we seem like your cup of tea, chat to Kerrie or Lucy and
              they’ll shout you one.
            </Message>
          </ContactMessage>
        </ContactFlexCol>

        <AddressFlexCol xs={12} sm={3}>
          <Address>
            <CommuLogoSml />
            <p>
              <a
                href="https://goo.gl/maps/JKdAP5BMUyzcvS517"
                target="_blank"
                rel="noopener noreferrer"
              >
                Level 1, 554 High Street Prahran VIC 3181 <br />
              </a>
              {/* <a href="tel:+61395229922"> + 61 3 9522 9922</a> */}
            </p>
          </Address>
        </AddressFlexCol>

        <NewFlexCol xs={6} sm={3}>
          <MenuItems>
            <Name>Kerrie Ryan</Name>
            <Details>Managing Director</Details>
            <Details>
              M <Phone href="tel:+61419804424">+61 419 804 424</Phone>
            </Details>
            <Details>
              <Email href="mailto:kerrie.ryan@communicado.com.au">
                Email Kerrie
              </Email>
            </Details>
          </MenuItems>
        </NewFlexCol>

        <NewFlexCol xs={6} sm={3}>
          <MenuItems>
            <Name>Lucy Houldsworth</Name>
            <Details>Director</Details>
            <Details>
              M <Phone href="tel:+61421079543">+61 421 079 543</Phone>
            </Details>
            <Details>
              <Email href="mailto:lucy.houldsworth@communicado.com.au">
                Email Lucy
              </Email>
            </Details>
          </MenuItems>
        </NewFlexCol>

        <NewFlexCol xs={12} sm={3}>
          <NewSocialLinks footer />
        </NewFlexCol>
      </FlexRow>
    </FlexGrid>
  </Container>
)
export default Divider
