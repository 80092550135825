import React, { useRef } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "./Header/index"
import Footer from "../components/Footer/footer"
import { ParallaxProvider } from "react-scroll-parallax"

import "../fonts/TitlingGothic/stylesheet.css"

const Layout = ({ children, template, showMenu }) => {
  // console.log(showMenu)
  const parallaxContainerRef = useRef(null)

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <div className="app-wrapper">
          <Header template={template} showMenu={showMenu} />
          <main>{children}</main>
          <Footer />
        </div>
      </ParallaxProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
