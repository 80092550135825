import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 42px;
  background: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 1;
  position: ${props => (props.template === 'home' ? 'fixed' : 'sticky')};



  ${props=> props.template === 'home' &&`
    transition: transform 400ms ease-in-out;
    transform: translateY(${props.showMenu ? '0' : '-100%'});
  `}



  @media ${device.tablet} {
    margin-bottom: 70px;
  }

  @media ${device.desktop} {
    margin-bottom: 100px;
  }

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`
