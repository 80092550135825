import styled from "styled-components"
import { device } from "breakpoints"
import { Grid, Col, Row } from "react-styled-flexboxgrid"

export const FlexBoxRow = styled(Row)``

export const FlexBoxCol = styled(Col)``

export const FlexBoxGrid = styled(Grid)`
  padding: 0;
  margin: 0 auto;
  width: ${props =>
    props.fullWidth
      ? "100%"
      : `calc(100% - 2*${props.theme.mobileGutter})`} !important;
  max-width: ${props => (props.fullWidth ? "100%" : props.theme.mainMaxWidth)};

  /* accounts for bottom row margin-bottom */
  margin-bottom: ${props =>
    props.rowGap
      ? `-${props.rowGap[0]}px`
      : props.gap
      ? `-${props.gap[0]}px`
      : `-${props.theme.mobileGap}px`};
  @media ${device.tablet} {
    margin-bottom: ${props =>
      props.rowGap
        ? `-${props.rowGap[1]}px`
        : props.gap
        ? `-${props.gap[1]}px`
        : `-${props.theme.tablet}px`};
  }

  @media ${device.desktop} {
    margin-bottom: ${props =>
      props.rowGap
        ? `-${props.rowGap[2]}px`
        : props.gap
        ? `-${props.gap[2]}px`
        : `-${props.theme.desktopGap}px`};
    width: ${props =>
      props.fullWidth
        ? "100%"
        : `calc(100% - 2*${props.theme.desktopGutter})`} !important;
  }

  @media ${device.large} {
    margin-bottom: ${props =>
      props.rowGap
        ? `-${props.rowGap[3]}px`
        : props.gap
        ? `-${props.gap[3]}px`
        : `-${props.theme.largeGap}px`};
  }

  /* accounts for padding on ends of each line */
  ${FlexBoxRow} {
    margin: ${props =>
      props.gap
        ? `0 -${props.gap[0] / 2}px`
        : `0 -${props.theme.mobileGap / 2}px`};

    @media ${device.tablet} {
      margin: ${props =>
        props.gap
          ? `0 -${props.gap[1] / 2}px`
          : `0 -${props.theme.tabletGap / 2}px`};
    }

    @media ${device.desktop} {
      margin: ${props =>
        props.gap
          ? `0 -${props.gap[2] / 2}px`
          : `0 -${props.theme.desktopGap / 2}px`};
    }

    @media ${device.large} {
      margin: ${props =>
        props.gap
          ? `0 -${props.gap[3] / 2}px`
          : `0 -${props.theme.largeGap / 2}px`};
    }
  }

  /* adds grid-column-gap and grid-row-gap*/
  ${FlexBoxCol} {
    margin-bottom: ${props =>
      props.rowGap
        ? `${props.rowGap[0]}px`
        : props.gap
        ? `${props.gap[0]}px`
        : `${props.theme.mobileGap}px`};
    padding: ${props =>
      props.gap
        ? ` 0 ${props.gap[0] / 2}px`
        : `0 ${props.theme.mobileGap / 2}px`};

    @media ${device.tablet} {
      margin-bottom: ${props =>
        props.rowGap
          ? `${props.rowGap[1]}px`
          : props.gap
          ? `${props.gap[1]}px`
          : `${props.theme.tabletGap}px`};
      padding: ${props =>
        props.gap
          ? ` 0 ${props.gap[1] / 2}px`
          : `0 ${props.theme.tabletGap / 2}px`};
    }

    @media ${device.desktop} {
      margin-bottom: ${props =>
        props.rowGap
          ? `${props.rowGap[2]}px`
          : props.gap
          ? `${props.gap[2]}px`
          : `${props.theme.desktopGap}px`};
      padding: ${props =>
        props.gap
          ? ` 0 ${props.gap[2] / 2}px`
          : `0 ${props.theme.desktopGap / 2}px`};
    }

    @media ${device.large} {
      margin-bottom: ${props =>
        props.rowGap
          ? `${props.rowGap[3]}px`
          : props.gap
          ? `${props.gap[3]}px`
          : `${props.theme.largeGap}px`};
      padding: ${props =>
        props.gap
          ? ` 0 ${props.gap[3] / 2}px`
          : `0 ${props.theme.largeGap / 2}px`};
    }
  }
`
