const primaryColor = "#004E9A"
const white = "#FFFFFF"
const black = "#3F3F3F"

const theme = {
  primaryFont: '"Source Sans Pro", arial, sans-serif',
  secondaryFont: '"TitlingGothic", arial, sans-serif',
  primaryColor: primaryColor,
  textColor: black,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: "#84004b",
  btnTextColor: white,
  mobileGutter: "20px",
  desktopGutter: "40px",
  mainMaxWidth: "1160px",
  mobileGap: 15,
  tabletGap: 20,
  desktopGap: 35,
  largeGap: 50,

  // colors: {
  //   grey: ["#F4F4F4", "#D7E1E2", "#9CACAE", "#4A5050", "#E2E6E9"],
  //   black: "#19191A",
  //   teal: "#24A49E",
  //   turquoise: "#00CDC2",
  //   pink: "#E4316E",
  //   zircon: "#D7E1E2",
  //   SanMarino: "#556CAA",
  //   BrightTurquoise: "#09CADB",
  //   Cardinal: "#CC1933",
  // },
}

export default theme
