import styled from "styled-components"
import { SocialLinks } from "../SocialLinks/index"
import { FlexCol } from "../FlexGrid/index"

import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 100px;

  @media ${device.desktop} {
    padding-top: 100px;
    padding-bottom: 130px;
  }
`
export const FooterMenuIcons = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const Menu = styled.div`
  margin-bottom: 17px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const MenuItems = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #9cacae;
  letter-spacing: 0;
  line-height: 24px;

  @media ${device.tablet} {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
    padding-top: 10px;
  }
  @media ${device.desktop} {
    padding-top: 0;
  }

  :hover {
    color: ${props => props.theme.primaryColor};
    transition: 80ms;
  }
`

export const ContactMessage = styled.div``

export const Message = styled.h4`
  font-weight: 700;
  font-size: 22px;
  color: #004e9a;
  letter-spacing: 0.31px;
  line-height: 26px;
  text-transform: uppercase;
  max-width: 460px;

  @media ${device.desktop} {
    font-size: 36px;
    letter-spacing: 0.5px;
    line-height: 42px;
    max-width: 756px;
  }
`
export const Address = styled.div`
  max-width: 240px;
  a {
    color: #3f3f3f;
  }

  @media ${device.desktop} {
    max-width: 160px;
    svg {
      margin-top: 10px;
      margin-left: 7px;
      transform: scale(1.2);
    }
  }

  p {
    font-size: 14px;
    color: #3f3f3f;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 11px;

    @media ${device.desktop} {
      font-size: 16px;
      line-height: 24px;
      margin-top: 15px;
    }
  }
`
export const Name = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #004e9a;
  letter-spacing: 0;
  line-height: 24px;

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
  @media ${device.desktop} {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 5px;
  }
`

export const Details = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #3f3f3f;
  letter-spacing: 0;
  line-height: 26px;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 30px;
  }
`

export const Phone = styled.a`
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
  letter-spacing: 0;
  line-height: 26px;
  cursor: pointer;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 30px;
  }

  :hover {
    opacity: 0.7;
  }
`
export const Email = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  color: #3f3f3f;
  letter-spacing: 0;
  line-height: 26px;
  cursor: pointer;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 30px;
  }

  :hover {
    opacity: 0.7;
  }

  position: relative;

  :after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ff7f41;
    opacity: 1;
  }
`

export const NewSocialLinks = styled(SocialLinks)``

export const NewFlexCol = styled(FlexCol)`
  margin-bottom: 40px !important;

  @media ${device.desktop} {
    padding: 0 20px !important;
    margin-top: 18px;
    margin-bottom: 100px !important;
  }
`
export const AddressFlexCol = styled(FlexCol)`
  margin-bottom: 35px !important;
`
export const ContactFlexCol = styled(FlexCol)`
  position: relative;
  :after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 5px;
    width: calc(100% - 20px);
    height: 2px;
    background: #e9e4e1;
    opacity: 1;

    @media ${device.desktop} {
      bottom: -50px;
      left: 25px;
      width: calc(100% - 50px);
    }
  }
`
