import React from "react"

import { SocialLinksContainer, SocialNewList } from "./style.js"

import { ReactComponent as FacebookLogo } from "./facebook-logo.svg"
// import { ReactComponent as InstagramLogo } from "./instagram-logo.svg"
import { ReactComponent as LinkedinLogo } from "./linkedin-logo.svg"

const SocialLinks = props => {
  return (
    <SocialLinksContainer className={props.className} footer={props.footer}>
      <SocialNewList className={props.className} direction="horizontal">
        <a
          href="https://www.facebook.com/communicadomarketing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookLogo />
        </a>
        <a
          href="https://au.linkedin.com/company/communicado-pty-ltd"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinLogo />
        </a>
        {/* <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramLogo />
        </a> */}
      </SocialNewList>
    </SocialLinksContainer>
  )
}

export default SocialLinks
