import React from "react"

import { NavBar } from "../NavBar"
import { HeaderContainer } from "./style"
import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid"


const Header = ({template, showMenu}) => {

  return (
    <HeaderContainer template={template} showMenu={showMenu}>
      <FlexGrid rowGap={[0]}>
        <FlexRow>
          <FlexCol xs="12" >
            <NavBar />
          </FlexCol>
        </FlexRow>
      </FlexGrid>
    </HeaderContainer>
  )
}

export default Header
